import React, { useEffect, useState } from 'react'
import { Rental } from '../../../domain/rental'
import { Box, Card, CardContent, CardHeader, CardMedia, Modal, Stack } from '@mui/material'
import { getPricingPlans } from '../../../apis/rental/city-apis'
import { useSelector } from 'react-redux'
import { constructImageDirectory } from '../../../utils/utils'
import * as Storage from 'firebase/storage'
import TextField from '@mui/material/TextField/TextField'
import Button from '@mui/material/Button'
import { payForRental } from '../../../apis/rental/rental-apis'
import CircularProgress from '@mui/material/CircularProgress'
import { NoImage } from '../../no-image/NoImage'
import { useShowToast } from '../../../hooks'
import { usePaymentEmailStr } from '../../../hooks/constant-hooks'

interface Props {
  rental: Rental
  onClose: () => void
}

export const RentalsItemPayment = ({ rental, onClose }: Props) => {
  const allCities = useSelector((s: any) => s.cities.citiesList)
  const [imageUrl, setImageUrl] = useState('')
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState('')
  const [emailStr, setEmailStr] = useState(usePaymentEmailStr())
  const [loading, setLoading] = useState(false)
  const showToast = useShowToast()

  const onSubmit = () => {
    setLoading(true)
    payForRental(rental.id, { amount: amount * 100, description, emailStr })
      .then(() => {
        onClose()
        showToast('Payment captured successfully', 'success')
      })
      .catch(() => {
        setLoading(false)
        showToast('Failed to capture payment', 'error')
      })
  }

  useEffect(() => {
    const cityCode = allCities.find((c: any) => c.id === rental.cityId)?.code

    if (rental?.cityId && cityCode) {
      getPricingPlans(rental.cityId).then((plans) => {
        const directory = constructImageDirectory({
          cityCode,
          pricingPlanType: plans.find((p) => p.id === rental.planId)?.type,
          id: rental.id,
        })

        const storage = Storage.getStorage()
        const storageRef = Storage.ref(storage, directory)

        Storage.list(storageRef, { maxResults: 1 })
          .then(({ items: [item] }) => {
            return item ? Storage.getDownloadURL(item) : ''
          })
          .then((url) => {
            setImageUrl(url)
          })
      })
    }
  }, [])

  return (
    <Modal className={'modalContainer'} open={true} onClose={() => onClose()}>
      <Card variant="outlined" sx={{ width: '80vw', height: '80vh', display: 'flex' }}>
        {imageUrl ? (
          <CardMedia component="img" src={imageUrl} sx={{ width: '40%' }} />
        ) : (
          <CardMedia sx={{ width: '40%' }}>
            <NoImage />
          </CardMedia>
        )}
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <CardHeader title={`Make a payment for ${rental.id}`} />

          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            <Stack spacing={3}>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <TextField
                  value={amount}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  label="Amount"
                  type="number"
                  sx={{ width: '40%' }}
                />
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth={true}
                  label="Description"
                />
              </Box>

              <TextField
                value={emailStr}
                onChange={(e) => setEmailStr(e.target.value)}
                multiline={true}
                rows={15}
                fullWidth={true}
                label="Email (Optional)"
              />
            </Stack>

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="contained" disabled={!amount || !description} onClick={onSubmit}>
                {loading ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : (
                  <>Capture {amount ? `${amount} ${rental.currency}` : ''}</>
                )}
              </Button>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Modal>
  )
}
