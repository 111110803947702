import { useSelector } from 'react-redux'
import { WhitelabelCodeReturnDecide } from '../utils/utils'

const mobyText = `Dear Moby Customer,

During a recent review, we noticed that your bike was parked in violation of our parking guidelines. To help clarify the situation, we’ve attached the image submitted at the end of your rental for your reference.

As per our parking policy, a fine of €30 has been applied to your account.

Why is this important?
Incorrectly parked bikes can disrupt public spaces and violate our agreements with local authorities. We understand this can be frustrating, so we provide parking guidance at the end of each rental to help you avoid any issues.

Where to Park Correctly:
You can avoid fines by parking only at any public bike parking rack, located on public property within our operating zone, which is indicated in the app.
When bikes are parked incorrectly, our team may need to remove and re-park them, especially if public complaints arise or the bike remains unmoved for a certain period.

If You Believe This Was a Mistake:
If you feel this fine was issued in error, simply reply to this message, and we’ll be happy to review the situation and address any concerns.

For more info: https://moby.zendesk.com/hc/en-us/categories/4407352764177-Dublin-City-Bike-Sharing-Scheme

Thank you for your understanding and cooperation.

Best regards,
The Moby Team
`

export const usePaymentEmailStr = () => {
  const whiteLabelCode = useSelector((state: any) => state.auth.whiteLabelCode)

  return WhitelabelCodeReturnDecide(whiteLabelCode, [``, mobyText, ``, ``, ``, ``])
}
